import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui';

function TextGrid(props) {
  const { slice } = props;
  const primary = slice.primary;
  const data = slice.items;
  return (
    <ContainerDefault>
      <Grid
        container
        justifyContent="center"
        spacing={7}
        className="text-grid-wrapper"
      >
        {data.map((item, key) => {
          return (
            <Box
              key={key}
              component={Grid}
              mb={4}
              item
              xs={parseInt(primary.grid_columns_xs)}
              md={parseInt(primary.grid_columns_md)}
              lg={parseInt(primary.grid_columns_lg)}
            >
              <Box sx={{ textAlign: 'center' }}>
                {item.icon && item.icon.url && (
                  <Box mb={3}>
                    <img
                      className={
                        parseInt(primary.grid_columns_lg) >= 4
                          ? 'm-w-60'
                          : 'm-w-80'
                      }
                      src={item.icon.url}
                      alt={item.icon.alt ? item.icon.alt : ''}
                    />
                  </Box>
                )}
                <RichTextField data={item.content.raw} />
              </Box>
            </Box>
          );
        })}
      </Grid>
    </ContainerDefault>
  );
}

TextGrid.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TextGrid;
