import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'components/ui';

function FullImage(props) {
  const { slice } = props;
  const data = slice.primary;
  return <Image src={data.image.url} alt={data.image.alt} />;
}

FullImage.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default FullImage;
