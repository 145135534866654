import React from 'react';
import PropTypes from 'prop-types';
import { ContainerDefault } from 'components/containers';
import { Image, RichTextField } from 'components/ui';
import Grid from '@mui/material/Grid';

function TextColumnWithImage(props) {
  const { slice } = props;
  const data = slice.primary;
  return (
    <ContainerDefault>
      <Grid
        container
        justifyContent="space-between"
        flexDirection={`${
          data.image_position == 'Left' ? 'row' : 'row-reverse'
        }`}
      >
        <Grid
          item
          xs={12}
          md={data.image_position == 'Left' ? 5 : 6}
          lg={data.image_position == 'Left' ? 5 : 6}
        >
          <Image src={data.image.url} alt={data.image.alt} />
        </Grid>
        <Grid
          item
          xs={12}
          md={data.image_position == 'Left' ? 6 : 5}
          lg={data.image_position == 'Left' ? 6 : 5}
        >
          <RichTextField data={data.content.raw} />
        </Grid>
      </Grid>
    </ContainerDefault>
  );
}

TextColumnWithImage.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TextColumnWithImage;
