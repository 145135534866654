import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import Layout from 'components/layout';
import Banner from 'components/banner';
import Box from '@mui/material/Box';
import { ResolveSlices } from 'components/slices';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'helpers/linkResolver';

function Page(props) {
  const { data } = props;
  const doc = data.prismicPage.data;
  if (!doc) return null;
  const bodyPadding = doc.display_banner ? 0 : 6;
  const pageTitle = 'Kaluza Careers | ' + doc.page_title.text;
  return (
    <Layout headerOpacity={1}>
      {!doc.is_homepage && (
        <Helmet>
          <meta content={pageTitle} name="apple-mobile-web-app-title" />
          <meta content={pageTitle} property="og:title" />
          <meta content={pageTitle} name="twitter:title" />
          <title>{pageTitle}</title>
          <meta content={pageTitle} name="application-name" />
          <meta content={pageTitle} property="og:site_name" />
        </Helmet>
      )}
      {doc.display_banner && (
        <Banner
          bannerColorTheme={doc.banner_color_theme}
          bannerContent={doc.banner_content.raw}
          bannerImage={doc.banner_image}
          isFront={doc.is_homepage}
        />
      )}
      <Box
        pt={bodyPadding}
        className={
          data.prismicPage?.uid === 'life-at-kaluza'
            ? 'life-at-kaluza--page-body'
            : 'page-body'
        }
      >
        {doc.body && <ResolveSlices data={doc.body} />}
      </Box>
    </Layout>
  );
}

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
]);

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query PageQuery($id: String!) {
    prismicPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        is_homepage
        display_banner
        banner_color_theme
        banner_content {
          html
          raw
          text
        }
        banner_image {
          alt
          url
        }
        page_title {
          html
          raw
          text
        }
        body {
          ... on PrismicPageDataBodyTextColumnWithImage {
            id
            slice_type
            primary {
              image_position
              image {
                alt
                url
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyImage {
            slice_type
            primary {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyTextBlock {
            slice_type
            primary {
              text_align
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyTextGrid {
            slice_type
            primary {
              grid_columns_lg
              grid_columns_md
              grid_columns_xs
            }
            items {
              icon {
                alt
                url
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyJobSearchListing {
            slice_type
            primary {
              intro_content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyImageTextBlockGrid {
            slice_type
            primary {
              block_background_color
              default_link {
                uid
                type
              }
            }
            items {
              reference_parameter
              image {
                alt
                url
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyVideo {
            id
            slice_type
            slice_label
            primary {
              video_url {
                uri
                html
                embed_url
              }
            }
          }
          ... on PrismicPageDataBodyLineBreak {
            slice_type
            primary {
              line_style
            }
          }
          ... on PrismicPageDataBodyPdfDownload {
            id
            slice_type
            primary {
              content_left {
                text
                raw
                html
              }
              content_right {
                html
                raw
                text
              }
              pdf_link {
                url
              }
              pdf_link_image {
                url
              }
            }
          }
        }
      }
    }
  }
`;
