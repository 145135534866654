import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui';

function TextBlock(props) {
  const { slice } = props;
  const data = slice.primary;
  return (
    <ContainerDefault>
      <Box sx={{ textAlign: data.text_align ? data.text_align : 'left' }}>
        <RichTextField data={data.content.raw} />
      </Box>
    </ContainerDefault>
  );
}

TextBlock.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default TextBlock;
