import React, { useContext } from 'react';
import { Context } from 'store/provider';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui';

import linkResolver from 'helpers/linkResolver';

function ConditionalWrapper(props) {
  const { children, hasDefaultLink, link, referenceParameter } = props;
  const [state, dispatch] = useContext(Context);

  const checkSetParam = val => {
    if (val) {
      dispatch({ type: 'SET_DEPTARTMENT_PARAM', payload: val });
    }
  };

  return hasDefaultLink ? (
    <Box
      component={Link}
      to={linkResolver(link)}
      onClick={() => checkSetParam(referenceParameter)}
      sx={{ textDecoration: 'none', color: 'inherit', height: '100%' }}
    >
      {children}
    </Box>
  ) : (
    <Box sx={{ height: '100%' }}>{children}</Box>
  );
}

ConditionalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  hasDefaultLink: PropTypes.bool.isRequired,
  link: PropTypes.object,
  referenceParameter: PropTypes.string,
};

function ImageTextBlockGrid(props) {
  const { slice } = props;
  const data = slice.primary;
  const items = slice.items;

  const hasDefaultLink =
    data.default_link && data.default_link.uid ? true : false;

  return (
    <Box sx={{ backgroundColor: data.block_background_color }} py={14}>
      <ContainerDefault>
        <Grid container spacing={2}>
          {items && (
            <React.Fragment>
              {items.map((item, key) => {
                return (
                  <Box
                    key={key}
                    component={Grid}
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    mb={4}
                  >
                    {item.image && item.image.url && (
                      <ConditionalWrapper
                        hasDefaultLink={hasDefaultLink}
                        link={data.default_link ? data.default_link : ''}
                        referenceParameter={
                          item.reference_parameter
                            ? item.reference_parameter
                            : ''
                        }
                      >
                        <Box
                          display="flex"
                          flexDirection="column"
                          sx={{
                            height: '100%',
                          }}
                        >
                          <img
                            className="w-100"
                            src={item.image.url}
                            alt={item.image.alt ? item.image.alt : ''}
                            style={{
                              borderTopLeftRadius: '7px',
                              borderTopRightRadius: '7px',
                            }}
                          />
                          <Box
                            py={4}
                            px={4}
                            sx={{
                              height: '100%',
                              textAlign: 'center',
                              backgroundColor: '#fff',
                              borderBottomLeftRadius: '7px',
                              borderBottomRightRadius: '7px',
                            }}
                          >
                            <RichTextField data={item.content.raw} />
                          </Box>
                        </Box>
                      </ConditionalWrapper>
                    )}
                  </Box>
                );
              })}
            </React.Fragment>
          )}
        </Grid>
      </ContainerDefault>
    </Box>
  );
}

ImageTextBlockGrid.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default ImageTextBlockGrid;
