import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Box from '@mui/material/Box';
import { ContainerDefault } from 'components/containers';
import { RichTextField, JobSearchUi } from 'components/ui';
import { JobsList } from 'components/slices';

function JobSearchListing(props) {
  const { slice } = props;
  const data = slice.primary;
  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
          allGreenhouseOffice(sort: { order: ASC, fields: name }) {
            edges {
              node {
                name
                gh_Id
              }
            }
          }
          allGreenhouseDepartment(sort: { order: ASC, fields: name }) {
            edges {
              node {
                name
                gh_Id
              }
            }
          }
          allGreenhouseJob(sort: { fields: title, order: ASC }) {
            edges {
              node {
                gh_Id
                title
                fields {
                  slug
                  employmenttype
                }
                offices {
                  name
                  gh_Id
                }
                departments {
                  gh_Id
                  name
                }
              }
            }
          }
        }
      `}
      render={indexData => (
        <React.Fragment>
          {data.intro_content && data.intro_content.raw && (
            <ContainerDefault>
              <RichTextField data={data.intro_content.raw} />
            </ContainerDefault>
          )}
          <ContainerDefault>
            <Box pt={4}>
              <JobSearchUi
                searchIndex={indexData.siteSearchIndex.index}
                offices={indexData.allGreenhouseOffice.edges}
                departments={indexData.allGreenhouseDepartment.edges}
              />
            </Box>
          </ContainerDefault>
          <Box pt={4}>
            <JobsList
              data={indexData.allGreenhouseJob.edges}
              departments={indexData.allGreenhouseDepartment.edges}
            />
          </Box>
        </React.Fragment>
      )}
    />
  );
}

JobSearchListing.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default JobSearchListing;
