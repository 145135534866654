import React from 'react';
import PropTypes from 'prop-types';
import { ContainerDefault } from 'components/containers';

function LineBreak(props) {
  const { slice } = props;
  const data = slice.primary;
  return (
    <ContainerDefault>
      <div style={{ borderTop: '1px solid lightgrey', width: '100%' }} />
    </ContainerDefault>
  );
}

LineBreak.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default LineBreak;
