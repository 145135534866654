import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Context } from 'store/provider';
import { ContainerDefault } from 'components/containers';
import { JobCard } from 'components/ui';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ContactSupportOutlined } from '@mui/icons-material';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = event => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function JobsList(props) {
  const { data, departments } = props;
  const [state, dispatch] = useContext(Context);
  const [allJobsList, setAllJobsList] = useState(data);
  const [rolesLengthString, setRolesLengthString] = useState(`
    ${allJobsList.length}`);
  const [locationsString, setLocationsString] = useState('all locations ');
  const [teamsString, setTeamsString] = useState('all teams ');
  const [typeString, setTypeString] = useState('all types');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - allJobsList.length) : 0;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    updateFilteredList();
  }, [state.role_search]);

  useEffect(() => {
    setRolesLengthString(allJobsList.length);
  }, [allJobsList]);

  useEffect(() => {
    var string;
    if (state.filter_location.length == 1) {
      string = `${state.filter_location.length} location `;
    } else if (state.filter_location.length > 1) {
      string = `${state.filter_location.length} locations `;
    } else {
      string = 'all locations ';
    }
    setLocationsString(string);
    updateFilteredList();
  }, [state.filter_location]);

  useEffect(() => {
    var string;
    if (state.filter_department.length == 1) {
      departments.map(item => {
        if (item.node.gh_Id == state.filter_department) {
          string = item.node.name;
        }
      });
    } else if (state.filter_department.length > 1) {
      string = `${state.filter_department.length} teams `;
    } else {
      string = 'all teams ';
    }
    setTeamsString(string);
    updateFilteredList();
  }, [state.filter_department]);

  useEffect(() => {
    var string;
    if (state.filter_type.length == 1) {
      string = `${state.filter_type.length} type `;
    } else if (state.filter_type.length > 1) {
      string = `${state.filter_type.length} types `;
    } else {
      string = 'all types ';
    }
    setTypeString(string);
    updateFilteredList();
  }, [state.filter_type]);

  useEffect(() => {
    if (state.department_param) {
      updateFilteredList(state.department_param);
      departments.map(item => {
        if (item.node.gh_Id == state.department_param) {
          setTeamsString(item.node.name);
        }
      });
    } else {
      setAllJobsList(data);
      clearFilters();
    }
  }, []);

  const updateFilteredList = val => {
    const deptParam = val ? true : false;
    let list = data.filter(item => {
      var includeItem = true;
      item.node.title.toLowerCase().indexOf(state.role_search.toLowerCase()) !==
      -1
        ? null
        : (includeItem = false);

      if (state.filter_location.length > 0) {
        includeItem = false;
        item.node.offices.map(item => {
          state.filter_location.includes(item.gh_Id)
            ? (includeItem = true)
            : null;
        });
      }

      if (
        state.filter_department.length > 0 &&
        item.node.departments &&
        item.node.departments[0] &&
        item.node.departments[0].gh_Id
      ) {
        state.filter_department.includes(item.node.departments[0].gh_Id)
          ? null
          : (includeItem = false);
      }

      if (val) {
        val === item.node.departments[0].gh_Id ? null : (includeItem = false);
      }

      if (state.filter_type.length > 0) {
        if (item.node.fields && item.node.fields.employmenttype) {
          const job_employment_type = item.node.fields.employmenttype
            .toLowerCase()
            .replace(/ /g, '_');
          state.filter_type.includes(job_employment_type)
            ? null
            : (includeItem = false);
        }
      }

      return includeItem;
    });
    setAllJobsList(list);
  };

  const clearFilters = () => {
    const values = [];
    dispatch({ type: 'FILTER_LOCATION', payload: values });
    dispatch({ type: 'FILTER_DEPARTMENT', payload: values });
    dispatch({ type: 'FILTER_TYPE', payload: values });
    dispatch({ type: 'SET_ROLE_SEARCH', payload: '' });
    dispatch({ type: 'SET_FILTER_RESET', payload: true });
  };

  return (
    <Box bgcolor="background.paper" py={10}>
      <ContainerDefault>
        <Box mb={6}>
          {allJobsList.length > 0 ? (
            <Typography component="p" variant="body1">
              {allJobsList.length == 1 ? 'Showing ' : 'Showing all '}{' '}
              <strong>{rolesLengthString}</strong>{' '}
              {allJobsList.length == 1 ? 'role ' : 'roles '} in{' '}
              <strong>{locationsString}</strong>
              and for <strong>{teamsString}</strong>
            </Typography>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={4}>
                <Typography component="p" variant="h3" align="center">
                  Sorry, there are no roles that match your search critera.
                </Typography>
              </Box>
              <Box>
                <Typography
                  component="button"
                  variant="h6"
                  align="center"
                  sx={{
                    cursor: 'pointer',
                    border: 0,
                    background: 'none',
                    color: 'text.lblue',
                    transition: 'color 0.3s ease-in-out',
                    '&:hover': {
                      color: 'text.lgrey',
                    },
                  }}
                  onClick={() => clearFilters()}
                >
                  Reset all filters
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
        {allJobsList && (
          <React.Fragment>
            <TableContainer>
              <Table aria-label="custom pagination table">
                <TableBody>
                  {(rowsPerPage > 0
                    ? allJobsList.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : allJobsList
                  ).map((item, key, arr) => (
                    <TableRow key={key}>
                      <JobCard
                        data={item.node}
                        last={arr.length - 1 === key ? true : false}
                      />
                    </TableRow>
                  ))}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                <TableFooter>
                  <Box mt={7} />
                  <TableRow>
                    {allJobsList.length > rowsPerPage && (
                      <TablePagination
                        sx={{ marginTop: '4rem' }}
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: 'All', value: -1 },
                        ]}
                        colSpan={3}
                        count={allJobsList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    )}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </ContainerDefault>
    </Box>
  );
}

JobsList.propTypes = {
  data: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
};

export default JobsList;
