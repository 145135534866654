import React from 'react';
import PropTypes from 'prop-types';
import { ContainerDefault } from 'components/containers';
import { RichTextField } from 'components/ui'; // Assuming this is where RichTextField is
import MaskGroupImage from 'images/Mask_group.png'; // Importing the image
import BackArrowIcon from 'images/back_arrow_icon.png';

function PdfDownload(props) {
  const { slice } = props;
  const data = slice.primary;

  return (
    <ContainerDefault>
      <div className="pdf-download-container">
        {/* Left Section: Render content_left */}
        <div className="pdf-download-section">
          <RichTextField data={data.content_left.raw} />
        </div>

        {/* Center Section: Render pdf_link_image */}
        <div className="pdf-download-section pdf-download-center">
          <a href={data.pdf_link.url} target="_blank" rel="noreferrer">
            <img
              src={data.pdf_link_image.url}
              alt="PDF Link"
              style={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '10px',
              }}
            />
          </a>
        </div>

        {/* Right Section: Render content_right */}
        <div className="pdf-download-section">
          <div style={{ marginBottom: '10px' }}>
            <img
              src={BackArrowIcon}
              alt="Arrow Icon"
              style={{ width: '40px' }}
            />
          </div>
          <RichTextField data={data.content_right.raw} />
        </div>
      </div>

      {/* CSS styles */}
      <style jsx>{`
        .pdf-download-container {
          background-image: url(${MaskGroupImage});
          background-color: #1c597b;
          background-size: cover;
          background-position: center;
          width: 100%;
          min-height: 555px;
          border-radius: 30px;
          padding: 20px;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }

        /* For larger screens, arrange sections in a row */
        @media (min-width: 768px) {
          .pdf-download-container {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }

          .pdf-download-section {
            flex: 1;
            margin: 0 20px;
          }

          .pdf-download-center {
            text-align: center;
          }
        }

        /* For mobile, stack sections vertically */
        @media (max-width: 767px) {
          .pdf-download-section {
            width: 100%;
          }
        }
      `}</style>
    </ContainerDefault>
  );
}

PdfDownload.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default PdfDownload;
