import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { ContainerDefault } from 'components/containers';

function VideoEmbed(props) {
  const { slice } = props;
  const data = slice.primary;
  return (
    <ContainerDefault>
      <div className="player-wrapper">
        <ReactPlayer
          className="react-player"
          url={data.video_url.embed_url}
          controls={true}
          width="100%"
          height="100%"
        />
      </div>
    </ContainerDefault>
  );
}

VideoEmbed.propTypes = {
  slice: PropTypes.object.isRequired,
};

export default VideoEmbed;
