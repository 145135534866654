import React from 'react';
import PropTypes from 'prop-types';
import { Spacer } from 'components/containers';
import {
  TextColumnWithImage,
  FullImage,
  TextBlock,
  TextGrid,
  JobSearchListing,
  ImageTextBlockGrid,
  VideoEmbed,
  LineBreak,
  PdfDownload,
} from 'components/slices';

function ResolveSlices(props) {
  const { data } = props;

  const sliceComponents = {
    text_column_with_image: TextColumnWithImage,
    image: FullImage,
    text_block: TextBlock,
    text_grid: TextGrid,
    job_search___listing: JobSearchListing,
    image___text__block_grid: ImageTextBlockGrid,
    video: VideoEmbed,
    line_break: LineBreak,
    pdf_download: PdfDownload,
  };

  const sliceZoneContent = data.map((slice, index, arr) => {
    const SliceComponent = sliceComponents[slice.slice_type];
    const removeBottomPadding =
      slice.slice_type === 'image___text__block_grid' ||
      slice.slice_type === 'job_search___listing'
        ? true
        : false;
    if (SliceComponent) {
      return (
        <Spacer
          key={`slice-${index}`}
          removeBottomPadding={removeBottomPadding}
        >
          <SliceComponent slice={slice} />
        </Spacer>
      );
    }
    return null;
  });

  return <main className="container">{sliceZoneContent}</main>;
}

ResolveSlices.propTypes = {
  data: PropTypes.array.isRequired,
};

export default ResolveSlices;
